.fadeIn {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  animation-fill-mode: forwards;
}
.shine {
  animation: shine 5s ease-in-out infinite;
}
@keyframes pop-out {
  0% {
    transform: scale3d(0, 0, 1) translateY(0);
    opacity: 1;
  }
  40% {
    transform: scale3d(1, 1, 1) translateY(-40px);
    opacity: 1;
  }
  100% {
    transform: scale3d(1.5, 1.5, 1) translateY(-50px);
    opacity: 0;
  }
}
@keyframes fadeInOutStar {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.8;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes upAndDown {
  from {
    transform: translateY(-10px);
    box-shadow: inset 0px 50.8326px 72.9904px -46.9224px rgba(255, 255, 255, 0.5), inset 0px 9.1238px 14.3374px -5.2136px #ffffff,
      inset 0px -106.879px 88.6312px -83.4176px rgba(96, 68, 145, 0.3),
      inset 0px 127.733px 130.34px -62.5632px rgba(202, 172, 255, 0.3), inset 0px 5.2136px 23.4612px rgba(154, 146, 210, 0.3),
      inset 0px 1.3034px 52.136px rgba(227, 222, 255, 0.2);
  }
  to {
    transform: translateY(10px);
    box-shadow: inset 0px 10.8326px 72.9904px -46.9224px rgba(255, 255, 255, 0.5), inset 0px 9.1238px 14.3374px -5.2136px #ffffff,
      inset 0px -106.879px 88.6312px -83.4176px rgba(96, 68, 145, 0.3),
      inset 0px 17.733px 130.34px -62.5632px rgba(202, 172, 255, 0.3), inset 0px 5.2136px 23.4612px rgba(154, 146, 210, 0.3),
      inset 0px 10.3034px 12.136px rgba(227, 222, 255, 0.2);
  }
}
@keyframes shine {
  10% {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes Thunder {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
}
@keyframes colorPalette {
  0% {
    color: #ff2314;
  }
  25% {
    color: #2fff8d;
  }
  50% {
    color: #5c2fff;
  }
  75% {
    color: #ffbc1e;
  }
  100% {
    color: #e205ff;
  }
}
@keyframes scaleBackground {
  0% {
    transform: scale(1.1, 1);
    opacity: 1;
  }
  50% {
    transform: scale(1, 1.1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.1, 1);
    opacity: 1;
  }
}
@keyframes shineBgNFT {
  0% {
    transform: scale(0.8) rotate(1);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.2) rotate(90deg);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(45deg);
    opacity: 1;
  }
}

@keyframes tada {
  0% {
    transform: translate(var(--translateX), var(--translateY)) scaleX(0.9);
  }

  2%,
  4% {
    transform: translate(var(--translateX), var(--translateY)) scale3d(0.85, 0.85, 0.85) rotate(-3deg);
  }
  6%,
  10%,
  14%,
  18% {
    transform: translate(var(--translateX), var(--translateY)) scale3d(0.9, 0.9, 0.9) rotate(3deg);
  }
  8%,
  12%,
  16% {
    transform: translate(var(--translateX), var(--translateY)) scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  20% {
    transform: translate(var(--translateX), var(--translateY)) scaleX(0.9);
  }
  100% {
    transform: translate(var(--translateX), var(--translateY)) scaleX(0.9);
  }
}

@keyframes haloScaleRotate {
  0% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-360deg) scale(1.1);
    opacity: 1;
  }
  10%,
  30%,
  60% {
    opacity: 0.9;
  }
  20%,
  40%,
  80% {
    opacity: 0;
  }
  50% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) scale(0.6);
  }
  100% {
    transform: translate(var(--translateX), var(--translateY)) rotate(360deg) scale(1.1);
    opacity: 1;
  }
}
/* 
@keyframes haloMove {
  0% {
    transform: translate(calc(var(--translateX) + 10%), var(--translateY)) scale(1);
  }
  20% {
    transform: translate(calc(var(--translateX) - 10%), var(--translateY)) scale(1.1);
  }
  30% {
    transform: translate(var(--translateX), var(--translateY)) scale(0.9);
  }
  40% {
    transform: translate(calc(var(--translateX) + 10%), calc(var(--translateY) + 10%)) scale(1);
  }
  50% {
    transform: translate(calc(var(--translateX) - 10%), calc(var(--translateY) - 10%)) scale(1.1);
  }
  60% {
    transform: translate(calc(var(--translateX) - 10%), calc(var(--translateY) - 10%)) scale(1.2);
  }
  70% {
    transform: translate(calc(var(--translateX) - 10%), calc(var(--translateY) + 10%)) scale(0.8);
  }
  80% {
    transform: translate(calc(var(--translateX) + 10%), calc(var(--translateY)- 10%)) scale(1.1);
  }
  80% {
    transform: translate(var(--translateX), calc(var(--translateY) - 10%)) scale(1);
  }
  90% {
    transform: translate(var(--translateX), calc(var(--translateY) + 10%)) scale(0.9);
  }
  100% {
    transform: translate(calc(var(--translateX) + 10%), var(--translateY)) scale(1);
  }
} */

@keyframes moveIn {
  0% {
    transform: translate(calc(var(--translateX) + var(--moveX)), var(--translateY));
  }

  100% {
    transform: translate(var(--translateX), var(--translateY));
  }
}

@keyframes moveInInfinite {
  0% {
    transform: translate(calc(var(--translateX) - var(--moveX)), var(--translateY));
  }

  50% {
    transform: translate(calc(var(--translateX) + var(--moveX)), var(--translateY));
  }

  100% {
    transform: translate(calc(var(--translateX) - var(--moveX)), var(--translateY));
  }
}

@keyframes attackBoss {
  0% {
    opacity: 1;
    transform: translate(var(--translateX), var(--translateY));
  }
  10% {
    transform: translate(var(--translateXMiddle), var(--translateYMiddle)) rotate(var(--rotate)) rotateZ(var(--rotateZ));
  }
  90% {
    transform: translate(var(--translateXMiddle), var(--translateYMiddle)) rotate(var(--rotate)) rotateZ(var(--rotateZ));
  }
  95% {
    opacity: 1;
  }
  100% {
    transform: translate(var(--translateXLast), var(--translateYLast)) rotate(var(--rotate)) rotateZ(var(--rotateZ));
    opacity: 0;
  }
}

@keyframes upAndDownDynamic {
  0% {
    transform: translate(var(--translateX), var(--translateY));
  }
  50% {
    transform: translate(var(--translateX), calc(var(--translateY) + 10px));
  }
  100% {
    transform: translate(var(--translateX), var(--translateY));
  }
}

@keyframes lightning {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  1% {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.7));
  }
  2% {
    background: rgba(0, 0, 0, 0.7);
  }
  70% {
    background: rgba(0, 0, 0, 0.7);
  }
  71% {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.7));
  }
  72% {
    background: rgba(0, 0, 0, 0.7);
  }
  90% {
    background: rgba(0, 0, 0, 0.7);
  }
  91% {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.7));
  }
  92% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes lightningCloud {
  0% {
    filter: brightness(80%);
  }
  1% {
    filter: brightness(150%);
  }
  2% {
    filter: brightness(80%);
  }
  70% {
    filter: brightness(80%);
  }
  71% {
    filter: brightness(200%);
  }
  72% {
    filter: brightness(80%);
  }
  90% {
    filter: brightness(80%);
  }
  91% {
    filter: brightness(150%);
  }
  92% {
    filter: brightness(80%);
  }
  100% {
    filter: brightness(80%);
  }
}

@keyframes fadeInOutThumb {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  2% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ring {
  74% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  75% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-0.6deg) skew(0.6deg);
  }
  76% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0.6deg) skew(0.6deg);
  }
  77% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  78% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  79% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-0.6deg) skew(0.6deg);
  }
  80% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  81% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-0.6deg) skew(0.6deg);
  }
  82% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0.6deg) skew(0.6deg);
  }
  83% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  84% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  85% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-0.6deg) skew(0.6deg);
  }
  86% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0.6deg) skew(0.6deg);
  }
  87% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  88% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-0.6deg) skew(0.6deg);
  }
  89% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0.6deg) skew(0.6deg);
  }
  90% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-0.6deg) skew(0.6deg);
  }
  91% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0.6deg) skew(0.6deg);
  }
  92% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  93% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  94% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  95% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-0.6deg) skew(0.6deg);
  }
  96% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0.6deg) skew(0.6deg);
  }
  97% {
    transform: translate(var(--translateX), var(--translateY)) rotate(-0.6deg) skew(0.6deg);
  }
  98% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0.6deg) skew(0.6deg);
  }
  99% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
  100% {
    transform: translate(var(--translateX), var(--translateY)) rotate(0) skew(0.6deg);
  }
}

@keyframes bossHaloCircleMove {
  0% {
    transform: translate(400%, 0);
  }
  20% {
    transform: translate(104%, 300px);
  }
  25% {
    transform: translate(104%, 300px);
  }
  45% {
    transform: translate(-200%, 300px);
  }
  65% {
    transform: translate(-300%, 300px);
  }
  85% {
    transform: translate(80%, -150px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes rotate {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 100% 0px;
  }
}

@keyframes circular {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes opacityUpgrade {
  0% {
    opacity: 0.1;
  }
  5% {
    opacity: 0.6;
  }
  10% {
    opacity: 0.2;
  }
  15% {
    opacity: 0.6;
  }
  20% {
    opacity: 0.2;
  }
  25% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.2;
  }
  35% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.2;
  }
  45% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.2;
  }
  55% {
    opacity: 0.6;
  }
  60% {
    opacity: 0.2;
  }
  65% {
    opacity: 0.6;
  }
  70% {
    opacity: 0.2;
  }
  75% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.2;
  }
  85% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.2;
  }
  95% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.2;
  }
}
