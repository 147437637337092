@import './animation.css';

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('../fonts/Roboto.ttf') format('truetype');
}

@font-face {
  font-family: 'Monument Extended';
  src: local('Monument'), url('../fonts/Monument.otf') format('truetype');
}
